@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  font-family: "Open Sans", Verdana, sans-serif;
}

.flag {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);
  border-radius: 3px;
  transition: opacity 0.5s;
}

.flag:hover {
  opacity: 0.7;
}

.rd3t-node {
  fill: #004569 !important;
  stroke-width: 0 !important;
}

.rd3t-leaf-node {
  fill: #00a692 !important;
  stroke-width: 0 !important;
}
.rd3t-link {
  stroke: #5a5a5a;
}
